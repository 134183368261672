#loginScreen, #waitingScreen{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    height: 100%;
}

#loginScreen .top{
    margin-top: 24px;
}

.email_login_box{
    background: #F4F4F4;
    padding: 0 16px 0 0;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
}

input[type="email"].email_login_input {
    padding: 12px 16px;
    border-radius: 12px;
    background: #F4F4F4;
    border: none;
    min-width: 95%;
}

input[type="email"].email_login_input:focus {
    padding: 12px 16px;
    border-radius: 12px;
    background: #F4F4F4;
    border: none;
}

.login_legal_check {
    max-width: 320px;
    margin: auto;
    align-items: start;
}

.login_legal_check .form-check-label{
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .login_legal_check .form-check-label {
        font-size: 14px !important;
    }
    #loginScreen,
    #waitingScreen {
        height: 90%;
    }
}   



/*PIN INPUT*/
.pincode-input-container  {
    max-width: 380px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.pincode-input-container .pincode-input-text{
    border-radius: 8px;
    background: #F4F4F4 !important;
    font-size: 24px;
    min-width: 0 !important;
}

@media screen and (max-width: 768px) {
    .pincode-input-container {
        max-width: 330px;
    }
    .pincode-input-container .pincode-input-text {
        font-size: 20px;
        width: 40px !important;
        height: 40px !important;
    }
}